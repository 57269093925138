.text-ellipsis
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tagline-homepage{
  position: absolute;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 0 5%;
  color: white;
  text-align: right;
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: bold;
  line-height: 1.7;
  text-shadow: 2px 2px 5px black;
  text-transform: uppercase;

}
.publication-thumb
{
  min-height: 200px !important;
  min-width: 200px !important;
  max-height: 250px !important;
  img{
    object-fit: cover;
    height: 200px;
    width: 100% !important;
  }
}
.btn-showmore{
  cursor: pointer;
  &:focus{
    box-shadow: none !important;
  }
}
.title-line {
  margin-left: 15px;
  margin-bottom: 15px;
  border: 3px solid #dcdee3;
  border-radius: 3px;
  overflow: hidden;
  flex: 1 1;
}
.mb-7{
  margin-bottom: 4rem;
}
.signin{
  text-shadow: 0 0 10px #e5e99e;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000 !important;
  a{
    color: #000 !important;
  }
}
.share-buttons{
  button{
    font-size: 11px;
  }
}
.custom-form-select{
  font-size: 16px;
  border-radius: 10px;
  height: 50px;
  padding: 0px 15px;
}

.custom-react-select{
  border-radius: var(--radius);
}

.slide-info {
  position: absolute;
  top: 85%;
  @media #{$small-mobile}  {
    margin-left: 1.2% !important;
  }  @media #{$large-mobile}  {
    margin-left: 3% !important;
  }@media #{$md-small}  {
    margin-left: 3.5% !important;
  }@media #{$md-layout}  {
  margin-left: 4.2% !important;
  }@media #{$lg-layout}  {
    margin-left: 4.2% !important;
  }@media #{$laptop-device}  {
    margin-left: 5.2% !important;
  }@media #{$extra-device}  {
  margin-left: 5.2% !important;
  }
}

.hide-slider {
  position: absolute;
  top: 85%;
  @media #{$small-mobile}  {
    margin-right: 1.2% !important;
  }  @media #{$large-mobile}  {
    margin-right: 3% !important;
  }@media #{$md-small}  {
    margin-right: 3.5% !important;
  }@media #{$md-layout}  {
  margin-right: 4.2% !important;
  }@media #{$lg-layout}  {
    margin-right: 4.2% !important;
  }@media #{$laptop-device}  {
    margin-right: 5.2% !important;
  }@media #{$extra-device}  {
  margin-right: 5.2% !important;
  }
}

.facebook {

  a {
      color: #242429;
    &:hover {
      color: #4267B2 !important;
      //color: #3b5998 !important;
    }
  }
  }
.supplier-name {
  a {
      color: black;
    &:hover {
      color: #4267B2 !important;
      //color: #3b5998 !important;
    }
  }
  }

.insta {
  a {
    color: #242429;

    &:hover {
      color: rgb(131, 58, 180) !important;
      //color: #2C6A93 !important;
    }
  }
}
.company-icons{
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 100%;
  padding: 5px;
}
.company-icons-bg{
  background-color: #6c1cff;
}

.twitter {
  a {
    color: #242429;
    &:hover {
      //color: darkgray !important;
      color: #00B6F1 !important;

    }
  }
}
.non-registered-blur{
  pointer-events: none;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
  background-color: #fff;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.sidebar{
  position: -webkit-sticky;
  position: sticky;
  //padding: 15px;
  //background-color: #fff;
}
.publications-button{
  button{
    background-color: #353071 !important;
    color: #fff !important;
  }
  li, button{
    background-color: #F3F3F9 !important;
    color: #000 !important;
    &:hover{

      background-color: #353071 !important;
      color: #fff !important;
    }
  }
}
.register-now{
  z-index: 1;
}
//.my-wrapper{
//  display: flex;
//  justify-content: space-between;
//}
.product-info{
  padding-right: 20px;
  padding-left: 5px;
}
.btn-pages{
  font-size: 16px !important;
  height: 60px;
  color: black;
  font-weight: 600;
}
.btn-company-page{
  position: relative;
  padding: 10px 15px;
  border: 1px solid #dbdbdb;
  span{
    color: #242429 !important;
    font-size: 14px;
    overflow: hidden;
  }

  }
.profile-header{
  font-size: 14px;
  font-weight: 600;
  height: 60px;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 60px;
}
.product-header{
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  height: 60px;
  background-color: #e9e9e9;
  position: relative;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 60px;
}
.cursor-pointer{
  cursor: pointer;
}
.margin-laptop-for-events{
  @media #{$laptop-device} {
    margin-left: 3rem;
  } @media #{$latop-and-above} {
  margin-left: 3rem;
} @media #{$extra-device} {
  margin-left: 3rem;
}
}
.event-container{
  //border: 1px solid black;
  //border-radius: 8px;
  //padding-right: 50px;
  //padding-left: 50px;
  min-height: 370px;
  //background-color: #e5e5e5;
  h3{
    line-height: 1.2;
  }
}
.profile-container{
    //margin-left: auto;
}
.profile-menu {
  //top: 1px!important;
  z-index: 11;
  //ul {
  //  padding: 0;
  //  margin-bottom: 0;
  //  display: flex;
  //  -webkit-box-align: center;
  //  align-items: center;
  //  white-space: nowrap;
  //  position: static!important;
  //  overflow-x: auto;
  //  padding-inline-start: 40px;
  //}
  div{
    margin-top: 30px;
    text-align: center;
    align-items: center;
    font-size: 15px !important;
    transition: opacity .15s ease-in-out;
    color: #242429 !important;
    font-weight: 600;
    cursor: pointer;
    height: 30px;
  }
}
.product-menu {
  top: 1px!important;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 11;
  ul {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    position: static!important;
    overflow-x: auto;
    padding-inline-start: 40px;
  }
  li{
    font-size: 15px !important;
    padding: 0 60px;
    position: relative;
    transition: opacity .15s ease-in-out;
    display: flex;
    align-items: center;
    color: #000 !important;
    height: 60px;
    font-weight: 600;
    cursor: pointer;
  }
}

.my-progress-bar {
  /* Reset any inherited styles */
  all: unset;

  /* Define your custom styles here */
  /* ... */
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}
.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.slide-image{
  img {
    height: calc(50vh - 100px);
    width: auto;
    margin: 0 auto; /* it centers any block level element */
  }
  @media only screen and (min-width: 992px)
  {
    object-fit: cover;
    height: 250px;
    width: 280px;
  }
  @media only screen and (max-width: 991px)
  {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  cursor: zoom-in;
}
.img_producto {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.5s ease-out;
}
.events-title{
  border-left: 2px solid #c4bcbc;
}
.events-location{
  margin: 0 !important;
}
.event-rightbar{
  background-color: #e7f1f8;
  border-radius: 10px;
  padding: 3px 3px 3px 3px !important;
  margin-left: 70px;
  h7{
    font-size: 14px;
    color: black !important;
  }
  p{
    font-size: 12px;
    color: #6c757d !important;
  }
}
.slick-thumb{
  display: flex;
  li.slick-active{
    img{
      filter: grayscale(0);
      border: 1px solid red;
    }
  }
  li{
    width: 57px;
    height: 57px;
    img{
      width:57px;
      height: 57px;
    }
  }
}
.slick-dots{
  //text-align: left;
}
.dropdown-menu{
  padding: 0 !important;
}
.dropdown-menu{
  min-width: 250px;
}
.user-corner{
  height: 60px;
  background-color: #85ABDC;
}
.avatar{
  //position:absolute;
  //top:50%;
  margin-top:-20px; /* half of height */
  //height: 16px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.avatar-small {
  display: inline-block;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  background: rgb(255, 64, 128);
  color: #fff;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
}

.avatar-big {
  display: inline-block;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 1px;
  background: #ff4080;
  color: #fff;
  text-align: center;
  font-size: 25px;
  text-transform: capitalize;
}


.dropdown-homepage{
  z-index: 999;
  margin-right: -10px;
  .dropdown-button {
    button{
      background-color: transparent !important;
      border: none;
    }
  }
  .currency-language-button{
    background-color: transparent !important;
    padding: 0 !important;
    border: none;
    &:focus{
      box-shadow: none !important;
    }
    &:hover{
      background-color: transparent !important;
      border: none;
    }
  }
}

.language-switcher{
  z-index: 999;
  margin-right: -10px;
}

.slick-dots{
  position: relative;
  //display: inline-block;
  margin: 0px;
  padding: 0;
  display: flex;
  li {
    position: relative;
    flex-wrap: wrap;
    margin: 0 5px;
    padding: 0;
    img{
      filter: grayscale(100%);
      border: 1px solid black;

    }
  }
  margin-bottom: 50px;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.float-product{
  background: #fff;
  box-shadow: 0 2px 8px 0 black;
  //padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  position: relative;
  margin-bottom: 20px;
}
.seller-details{
  list-style-type: none !important;

  p{
    font-size: 14px !important;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
.company_desc{
  list-style: none;
  font-size: 14px !important;
 h1,h2,h3,h4,h5,h6,li,ul{
    font-size: 14px !important;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  p{
    font-size: 14px !important;
    margin: 0 !important;
    word-break: break-word;
  }

}
.gallery-item{
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.data-element{
  border: 1px solid #ebebeb;
  border-radius: 5px;
  box-shadow: 0 6px 16px rgba(1,1,1,0.12);
  background: #fff;
  padding: 20px;
  margin-bottom: 25px;
  width: 100%;
}
.data-element-sidebar{
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background: #fff;
  padding: 20px !important;
  margin-bottom: 25px;
}
.company-page-banner{
  img{  border-radius: 5px;
  }
}
.ml_dev-15{
  margin-left: -15px !important;
}
.data-element-sidebar-product{
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding: 15px !important;  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;
}
.price{
  font-size: 14px !important;
}
.listing-details
{
  border-top: none;
  background: #fff;
  height: 55px;
  -webkit-box-align: center;
  align-items: center;

  ul{
    padding-top: 4px;
    @media #{$sm-layout} {
      margin-top: 30px;
    }
    list-style-type: none;
    left: 15px;
    max-width: 100%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 5px;

    li{
      float: left;
      white-space: nowrap;

    }
  }
  .cat-icon{
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-right: 10px;
    padding-top: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
}
.cat-icon{
  border-radius: 50%;
  float: left;
  overflow: hidden;
}
.ld-info{
  right: 15px;
  padding-right: 15px;
  width: auto !important;

  ul{
    float: left;
    margin-left: 7px;
    li{
      float: left;
      margin-left: 7px;
      :last-of-type {
        overflow: visible;
      }
    }
  }
  a {
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 50%;
    background: #f4f4f4;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }
}
.currency-list .dropdown-menu {
  height: 80vh !important;
  overflow-y: scroll;
  min-width: 140px !important;
  margin-right: 10px;
  inset: 0 !important;
  //transform: translate(-30px,26px);
}
.category-name {
  color: #242429;
  font-size: 14px;
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //float: none;
}
.tagline-overflow{
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c27-listing-preview-category-list{
  text-align: left;
  z-index:1;
}


.lf-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 9;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  top: -30px;
  left: 15px;
}
.lf-item-container {
  border-radius: 5px;
  background-color: white;
  .listing-details{
    ul{
      //position: absolute;
      left: 15px;
      max-width: 100%;
      margin-bottom: 0;
    }
  }
  }
.lf-item-info-2 {
  margin-top: calc(-60px);
  margin-bottom: 0;
  color: #242429;
  background: #fff;
  left: 0;
  width: 100%;
  transform: translateY(calc(100% - 80px)) !important;
  padding: 37px 15px 0px;
  transition: all .9s;
  //-webkit-transform: translateZ(0);
  text-align: left;
  span{
    p{
      visibility: hidden ;
    }
  }
  &:hover{
    transform: translateY(0) !important;
    span{
      p{
        visibility: visible ;
      }
    }

  }
ul.lf-contact{
  margin-bottom: 0;
  list-style: none;
  span{

    &:hover{
      display: block;
    }
  }
  li{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 13px;
    color: #242429;
    font-weight: 400;
    //padding-right: 10px;
    white-space: nowrap;
  }
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 13px;
  color: #242429;
  font-weight: 400;
  //padding-right: 10px;
  white-space: nowrap;
}
  .lf-contact{
    font-size: 13px;
    color: #242429;
    font-weight: 400;
  }
.search-font{
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Roboto Slab", GlacialIndifference,sans-serif !important;
  color: #242429 !important;
  line-height: 1.25 !important;
}
  .prod-price{
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    color: black !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    font-size: 11px !important;
  }
  .tagline-font{
    color: #7e7e89 !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    max-height: 36px !important;
    margin-top: 4px !important;
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }
}
.product-tagline{
  font-size: 14px !important;
}
.body-font{
  font-size: 14px !important;
}
.flex-container{
  display: flex;
  background-color: #f1f1f1;
  div {
    font-size: 14px;
    margin-right: 2px;
  }
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding-right: 10px;
  padding-left: 10px
}
.btn-contact{
  display: block;
  width: 100%;
  height: 32px;
  border-radius: 18px;
  margin-bottom: 12px;
  background: #fff;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  padding: 6px;
  text-align: center;
  &:hover{
    color: var(--button-color);
  }
}
.moq{
    font-size: 12px !important;
    color: #999 !important;
    margin: 0;
    word-break: break-word !important;
}
.btn-chat{
  display: block;
  width: 100%;
  height: 32px;
  border-radius: 18px;
  margin-bottom: 12px;
  background: var(--button-color);
  color: #fff;
  padding: 6px;
  text-align: center;
  &:hover{
    background: var(--button-color);
    color: white;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}


.no-padding{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.pub-items {
  img{
    object-fit: none;
  }
  margin-top: 30px;
  border-radius: 10px;
  background: #f5f8fa;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  transition: 0.3s;
  padding-bottom: 15px;

  @media #{$lg-layout} {
  }
  @media #{$md-layout} {
  }
  @media #{$sm-layout} {
  }

  .icon {
    color: var(--color-primary);
    border-radius: 100%;
    font-size: 40px;
    display: inline-block;
    margin-bottom: 17px;
    line-height: 6px;
  }
  .inner {
    h4 {
      &.title {
        font-size: 18px;
        font-weight: 700;
        padding-left: 15px;
      }
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
      @media #{$lg-layout} {
        font-size: 16px;
      }
      @media #{$md-layout} {
        font-size: 16px;
      }
      @media #{$sm-layout} {
        font-size: 16px;
      }
      br {

        @media #{$lg-layout} {
          display: none;
        }

        @media #{$md-layout} {
          display: none;
        }

        @media #{$sm-layout} {
          display: none;
        }
      }
      a {
        color: var(--color-body);
        @extend %transition;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
  &:hover {
    transform: translateY(-5px)
  }
}

.box {
  padding: 0 5px 0 5px;
}
.box .inner {
  background-color: #fff;
}

.centered {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
}
.custom-size-spinner {
  width: 5rem;
  height: 5rem;
}

// If you don't use SASS/SCSS in your project
// click View Compiled CSS inside top/right CSS editor dropdown

$spinner-size: 75px !default;
$spinner-color: #69717d !default;
$overlay-color: black !default;

@mixin absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.overlay {
  background: rgba($overlay-color,.9);
  @include absolute-center;
}

.spinner-custom {
  font-size: $spinner-size;
  position: relative;
  display: inline-block;
  width: 5em;
  height: 5em;

  &.center {
    @include absolute-center;
  }
}

.sr-only-custom {
  position: absolute;
  left: .4629em;
  bottom: 0;
  width: .074em;
  height: .2777em;
  border-radius: .5em;
  background-color: transparent;
  transform-origin: center -.2222em;
  animation: spinner-fade 1s infinite linear;

  $animation-delay: 0s;
  $blade-rotation: 0deg;

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      animation-delay: $animation-delay;
      transform: rotate($blade-rotation);
      $blade-rotation: $blade-rotation + 30;
      $animation-delay: $animation-delay + .083;
    }
  }
}

@keyframes spinner-fade {
  0% {
    background-color: $spinner-color;
  }

  100% {
    background-color: transparent;
  }
}

#sourcing {
  border: none;
  color: var(--button-color);
}

//Product and company page register now card
.register-now-card
{
  background-color: #5B73E8 !important;
  Button{
    background-color: #34C38F;
    color: #fff;
    }
  p{
    color: #fff !important;
  }
}
.badge{
  position: absolute;
  right: 65px !important;
  top: 23px;
}
.cart-badge{
  position: absolute;
  right: 105px !important;
  top: 23px;
}

//PrivacyPolicy
.categories {
  .categories-submenu{
    min-width: 270px !important;
  }
  button {
    background-color: transparent !important;
    margin-top: 4px;
  }
  ul{
    li{
      position: relative;
      font-size: 14px;
      line-height: 1.4;
      padding: 0 12px !important;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      outline: none;
      transition: all 160ms ease-out;
      white-space: nowrap;
      display: flex;
      align-items: center;
      color: #172B4D;
    }
  }

}

.slick-track {
  height: auto !important;
}
.sourcing-content{
  position: relative;
}
.sourcing-form{
  @media only screen and (min-width: 992px)
  {
    float: right;
    padding: 20px 25px;
    background: #fff;
    margin-left: 225px;
    width: 530px;
  }
}
.sourcing-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.ads-img{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.ads-container{
  margin-top: -30px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}

.easy-sourcing{
  margin-top: 20px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}

.sourcing-text{
  padding-top: 20px;
  padding-left: 30px;
  overflow: hidden;
}
.sourcing-title {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  transform: scaleX(1.2);
  transform-origin: left;
}
.sourcing-desc {
  font-size: 16px;
  color: #555;
  p {
    color: #fff !important;
  }
}
.form-title {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: normal;
}
.form-text-sourcing{

  border-color: hsl(0, 0%, 80%);
  border-top-color: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 37px !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.form-text-input{
  input{
    border-radius: 0 !important;
  }}
.css-2613qy-menu{
  z-index: 100;
}
@media (max-width: 575px){
  .d-xs-none{
    display: none;
  }
}
.min-height-200{
  min-height: 800px
}
.quick-view{
  p{
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.message-modal{
  @media (min-width: 800px)  {
    .modal-content {
      width: auto !important;
      right: 2vh !important;
      //min-width: 20vh !important;
      bottom: 0 !important;
      position: fixed !important;
    }
  }

}
.cat-link{
  display: block;
}
.username{
  @media  (max-width: 508px) {
    display: none;
}
}
.email{
  overflow: hidden;
}
.static-page{
  p{
    font-size: 18px !important;
  }
  ol{
    li{
      color: black;
      ul{
        li{
          list-style-type: disc;
          color: black;
        }
      }
    }
  }
  ul{
    li{
    list-style-type: disc;
      color: black !important;
      ol{
        li{
          list-style-type: lower-alpha ;
        }
      }
    }
  }
}
.img.tag {
  position: relative;
}
/* set the base styles all tags should use */
.img.tag::before {
  position: absolute;
  top: 10%;
  display: block;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: bold;
}
/* Specific variations for different tags */
.img.tag-featured::before {
  content: "Featured";
  background: #B73439;
  color: #fff;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
}
.img.tag-sale::before {
  content: var(--saleContent, "Sale"); /* Use CSS variable to set content */
  background: #B73439;
  color: #fff;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
}
.img.tag-out::before {
  content: "Out of Stock";
  background: #e2e2e2;
  border: #ccc;
  color: #444;
  top: 42%;
  right: 0;
  left: 0;
  width: 30%;
  margin: 0 auto;
  text-align: center;
}

.shadow-none{
  box-shadow: none !important;
}
// .react-pdf__Page__canvas {
//   //width: 100% !important;
//   //height: 100% !important;
// }

.selectall{
  background-color: #ffffff;
  margin: 0 0px;
  width: 100% ;
  padding: 3px;
  //margin-top: 20px;

}

//Chat Window
.sc-chat-window{
  z-index: 2;
  height: calc(100% - 200px) !important;
  width: 300px;
  min-width: 300px !important;
  bottom: 0 !important;
  right: 0 !important;
}
.sc-header{
  height: 45px !important;
  min-height: 45px !important;
}
.sc-message--text{
  border-radius: 18px !important;
  padding: 9px 11px !important;
}
.sc-user-input{
  min-height: 30px;}
//.sc-open-icon{
//  height: 30px !important;
//  width: 30px !important;
//}
//.sc-launcher:before{
//  height: 30px !important;
//  width: 30px !important;
//}
//.sc-closed-icon{
//  height: 30px !important;
//  width: 30px !important;
//}
.sc-launcher{
  //height: 30px !important;
  //width: 30px !important;
  z-index: 2;
}

.follow-this{
  //&:hover{
  //  transform: translate(70px, 0px);
  //}
  top: 30% !important;
  bottom: auto;
  right: 0;
  float: right;
  left: auto;
  position: fixed;
  width: 3pc;
  z-index: 10002;
  transition: all .9s;

  ul{
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
  li{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 5px 0 5px 15px;

  }
}
  .twitter-follow{
    background-color: #1C96E8;
    padding: 5px 0 5px 15px;
  }
  .facebook-follow{
    background-color: #38529A;
  }
  .youtube-follow{
    background-color: #F70000;

  }
  .insta-follow{
    background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  }
  .linkedin-follow{
    background-color: #0073B1 !important;
  }
  .hide-slider-button{
    background-color: #898a8e !important;
  }
}
.show-follow-this{
  float:left;
  top: 30% !important;
  bottom: auto;
  right: 0;
  left: auto;
  position: fixed;
  z-index: 10002;
  ul{
    list-style: none;
  }
}

//Landing elements with smooth behaviour to ids
html{
  scroll-behavior: smooth !important;
}
#query, #faqs, #products  {scroll-margin-top: 100px;}
#profile, #product, #gallery, #askforpice{
  scroll-margin-top: 200px;
}
.filter-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  h6 {
    margin-bottom: 0;
  }
  Button{
    background-color: #138496;
  }
}
//.company-profile-menu:hover + .edit-company{
//  display: block !important;
//}
//.edit-company:hover{
//  display: block !important;
//}
//.edit-products{
//  display: none;
//}
//.edit-products:hover{
//  display: block !important;
//}
//.my-shop:hover + .edit-products{
//  display: block !important;
//}

// div{
//   margin-top: 30px;
//   text-align: center;
//   align-items: center;
//   font-size: 15px !important;
//   transition: opacity .15s ease-in-out;
//   color: #242429 !important;
//   font-weight: 600;
//   cursor: pointer;
//   height: 30px;
// }

.custom-border-2{

  border-top: 25px Solid #F2F3F7;

  }
  .mt-5 {

    margin-top: 25px;

  }

.data-element{
  border-radius: 20px;
  box-shadow: #0d8d2d;
}
  .cart{
    img{
      object-fit: contain;
      height: 150px;
    }
    .price{
      font-size: 14px;
    }

  }

.deliveryValue{
  margin-bottom: 0px;
}

.checkoutButton{
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  background-color: #0d8d2d;
  font-size: 14px;
  color: white;
  :hover{
    color: white;
  }
}

.text-center col-3 d-flex column{
  display: flex;
  justify-content: center;
  align-content: center;
}
.crossbtn{
  //margin-right: 40px;
  margin-left: 300px;
}
//.font-weight-semibold{
//  font-size: 20px;
//}

.media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row{
  flex-wrap: nowrap;
}

.wrapper {
  width: 100px;
  margin: 4em auto 0;
}
.btn-add{
  background-color: transparent !important;
  border: none;
}

.content-block{
  background-color: #ffffff;
  box-shadow: 0 6px 16px rgba(1,1,1,0.12);
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 12px;
}



.content-supplier{
  padding-left: 20px;
  background-color: #ffffff;

}
.input-form{
  border: 1px solid #e5e5e5
}

.image-product{
  width: 80px;
  height: 100px;

}

.pTags{
  font-size: 12px;
  margin: 0;
}

.p-right{
  padding-left: 5rem;
  padding-right: 5rem;
}

.border-icon{
  border: 1px solid #d7ddde;
  border-radius: 10px;
}
.orderMessageSuccess{
  background-color: #3873ae;
  color: white;
  border-radius: 10px ;
  margin-top: 10px;
}
.orderMessageDanger{
  background-color: #ab2d0a;
  color: white;
  border-radius: 10px ;
  margin-top: 10px;
}

.quantity-buttons {
  border: 0.5px solid grey;
  // border-radius: 100px;
}

.fontSizeTwelve{
  font-size: 12px;
}

.margin-botom{
  margin-bottom: 3px;
  margin-top: 1px;
}
.image-product-order-success{
  width: 80px;
  height: 80px;
  margin-top: 12px;


}

.padding-relative{
  //padding-top: 120px;
  padding-bottom: 20px;
  bottom: 45px;
}

.margin-bottom-for-space{
  margin-top: 100px;

}

.btn-adjustment{
  display: flex;
  margin-top: 5px;
  margin-left: -30px;
  //margin-top: 10px;

}

.button-width{
  width: 130px;
  padding-bottom: 10px;
  margin-top: 3px;
  padding: 7px !important;
}

.width-980px{
  width: 1110px;
}
.vl{
  border-left: 1px dotted darkgray;
  height: 110px;
}
// Add to Cart Modal
.hover-dark{
  background-color: #f8f9fa ;
}
.hover-dark:hover {
background-color: #efeff7;
}
.padding-left-right{
  padding-left: 10px;
  padding-right: 10px;
}

//checkout page
.h5-heading{
  margin-block: 4px;
  //font-size: 15px;

}
.bg-box-shadow{
  //border: 10px solid #C0C0C0;
  //border-style: solid;
  //border-width: 10px;
  //border-color: #b7b7c3;
  box-shadow: inset 0 0 20px #787878;

}

//custom.scss

.text-size-14{
  font-size: 14px !important;
}

.button-border{
  border: 1px darkgray;
}


  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //grid-gap: 10px;
    list-style-type: circle;
  }


.may_like_container
{
  width: 50% !important;
}


.grid-item {
  width: 100%;
}

.yellow-percent{
  color: orange;
}


.un-ordered{
  list-style-type: circle;
  //color: black;
  //display: flex;
  //flex-direction: row;
}
.line-index{
  text-align: center;
  padding: 10px;
}

.under-line{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

}

.background-black{
  color: black;
}

.background-gray{
  background-color: #f3f3f3;
}

.checkBoxStyle{
  width: 14px;
  padding-right: 0px;

}
.textDecorationUnderLine{
  text-decoration: underline;
}

.borderBottom{
  border-bottom: 6px solid white !important;
}

.goog-logo-link{
  display:none !important;
 }
.goog-te-gadget {
  a{
    display: none;
  }
}

.goog-te-banner-frame{
  .skiptranslate {
  display: none !important;
  } 
body {
  top: 0px !important; 
  }
}


.custom-switcher{
  .switch-item {
    .label{
    font-size: 12px;
    font-weight: 700;
    color: #000 !important;
    padding-bottom: 8px;
    }
  }
}

.skiptranslate{
  display: none;
}

.skipMyTranslate {
  .skiptranslate{
    display: block;
  }
}

.sale-product{
  text-decoration: line-through;
}

.product-price{
  color: red !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}

.product-price-strikethrough{
  font-size: 11px !important;
  font-weight: 400 !important;
  text-decoration: line-through !important;
}

.discount-percentage{
  margin-left: 4px;
  font-size: 9px !important;
  font-weight: 400 !important;
  color: #000;
}

.border-radius-10{
  border-radius: 10px;
}


.spaced-tabs{
  display: flex;
  justify-content: space-between;
}

.custom-tab-button{
  button{
    font-weight: 500;
  }
}

.icon-container {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 2px 1px 2px; /* Adjust the padding as needed */
  background-color: black; /* Background color for the icon container */
}
.product-page-ad-container {
  position: relative;
  overflow: hidden;
  border: 1px solid grey;
}

// Shipping modal css
.logistics--item {
  background: #f9f9f9;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 12px;
  padding: 12px 50px 12px 12px;
  position: relative;
}

.dynamic-shipping {
  color: #222;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
}

.dynamic-shipping-line {
  padding: 2px 0;
}

.logistics--unselectedIcon {
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 16px;
  position: absolute;
  right: 18px;
  top: 18px;
  width: 16px;
}

.logistics--selectedIcon {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-image: -webkit-linear-gradient(left,#ff7539,#ff0a0a);
  background-image: linear-gradient(90deg,#ff7539,#ff0a0a);
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 18px;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  right: 18px;
  top: 18px;
  width: 18px;
}

.product-quantity {
  color: #191919;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 0;
}

// Zoom animation
@keyframes zoom {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
    /* Zoom in quickly */
  }

  50% {
    transform: scale(1);
    /* Return to normal size quickly */
  }

  75% {
    transform: scale(1.2);
    /* Zoom in quickly again */
  }

  100% {
    transform: scale(1);
  }
}


.zoom-animation {
  animation: zoom 10s; /* Run the animation for 5 seconds */
}